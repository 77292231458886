<template>
  <div class="text-left">
    <BaseHeader :title="'System Users'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>

      <!-- {{clients}} -->
      
      <div class=" mt-2 border-0 ">
        <div class="text-right p-3 card d-flex align-items-center flex-row w-100 justify-content-end " style>
          
          <!-- <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter System Users"
            name="url"
            type="text"
            hide-details
          /> -->
          <!-- <v-spacer></v-spacer> -->
          <div class="w-50 ">
            <button
            class="my-auto float-right btn-add ml-2"
            @click.stop="
              $store.state.dialog = true;
            "
            >Add System Users</button>
          </div>
          
        </div>
       

        <div class="card orderlisting--card py-2">


          <div class=" mx-0  pref-table-custom ">
          
            <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
              <div class="col-2 text-left text--dark--custom "> ID </div>
              <div class="col-2 text-left text--dark--custom ">Full Name</div>
              <div class="col-2 text-left text--dark--custom">Email</div>
              <div class="col-2 text-left text--dark--custom"> Phone</div>
              <div class="col-2 text-left text--dark--custom"> Status</div>
              <div class="col-2 text-left text--dark--custom">Action</div>
          </div>
        

          <div v-if="clients.length > 0" class="">
          <div v-for="(item, index) in clients" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
              <div class="col-2 d-flex align-items-start justify-content-start text-left">	
                <span style="font-weight:500!important" class="px-2"
                  >{{ item.id || "" }}</span>
                
              </div>
              <div class="col-2 text-left">
                <div style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.first_name || "" }} {{item.last_name || "" }} </div>
              </div>
              <div class="col-2 text-left">{{ item.email || "" }}</div>
              <div style="font-weight:500!important" class="col-2 text-left">  
                {{item.phone}}
                
              </div>
              <div style="font-weight:500!important" class="col-2 text-left">  
                <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
                  item.status ? "Active" : "Inactive"
                }}</v-chip>
                
              </div>

              
              <div class="col-2 text-left  ">
                <v-btn
              fab
              elevation="0"
              x-small
              :to="{ name: 'ViewSystemClient', params: { id: item.id || '' } }"
            >
            <v-icon scale="1.3" name="eye" color="black" />

            </v-btn>
              </div>
          </div>
          </div>

          </div>
          <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="clients.length < 1">
            
                <strong class="order_empty  text-center" >No System Users</strong>
            
          </div>
        </div>
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">New client</v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill"
              ref="clientsForm"
              @submit.prevent="createClient"
            >
              <div class>
                <div class="text-left mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.first_name"
                        name="first_name"
                        type="text"
                        class="form-control height-60 "
                        placeholder="First name"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.last_name"
                        name="last_name"
                        type="text"
                        class="form-control height-60"
                        placeholder="Second name"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-right
                  "
                >
                  <input
                    required
                    v-model="clientsForm.email"
                    name="email"
                    type="email"
                    class="form-control height-60"
                    placeholder="Email Address"
                  />
                </div>

                <div class="row">
                  <div class="col-sm-4">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <vue-select
                        label="name"
                        required
                        v-model="clientsForm.country"
                        placeholder="Country: Start typing to search"
                        :options="countries"
                        :reduce="(country) => country.name"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.phone"
                        name="phone"
                        type="tel"
                        class="form-control height-60"
                        placeholder="Your Phone"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <vue-select
                        label="name"
                        required
                        v-model="clientsForm.site_id"
                        placeholder="Site: Start typing to search"
                        :options="sites"
                        :reduce="(site) => site.id"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.password"
                        name="password"
                        type="password"
                        class="form-control height-60"
                        placeholder="Create password"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.password_confirmation"
                        name="password_confirmation"
                        type="password"
                        class="form-control height-60"
                        placeholder="Repeat password"
                      />
                    </div>
                  </div>
                </div>

                <button
                  :disabled="clientsForm.busy"
                  type="submit"
                  style="border-radius:40px!important;"
                  class="btn btn-add p-3 w-50"
                >
                  <b>
                    <i class="icon-plus3"></i>
                  </b>
                  Create Client
                </button>
              </div>
            </form>
            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->



    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Clients",
  computed: {
    ...mapState("clients", ["clientsForm", "loading", "clients", "headers"]),
    ...mapState("sites", ["sites"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("sites", ["getSites"]),
    ...mapActions("clients", ["getClients"]),
    createClient() {
      this.clientsForm
        .post(`${this.$baseUrl}/Sites/Clients`)
        .then((res) => {
          this.getClients();
          this.$store.state.dialog = false;
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text: "Client created successfully.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
         

          this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
              ? err.response.data.Message
              : "Error while creating client",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },
  async mounted() {
    await this.getClients();
    await this.getSites();
  },
};
</script>